import React from "react";
import Layout from "../../components/Layout";
import { StoreContext } from "../../context/store-context";
import LineItem from "../../components/LineItem";
import { formatPrice } from "../../utils/formatPrice";
import Loader from "../../components/Loader";
import { gql, useMutation } from "@apollo/client";
import { getUser } from "../../components/Auth";

const ASSOCIATE_CUSTOMER = gql`
  mutation checkoutCustomerAssociateV2(
    $checkoutId: ID!
    $customerAccessToken: String!
  ) {
    checkoutCustomerAssociateV2(
      checkoutId: $checkoutId
      customerAccessToken: $customerAccessToken
    ) {
      checkout {
        id
      }
      checkoutUserErrors {
        code
        field
        message
      }
      customer {
        id
      }
    }
  }
`;

const Cart = () => {
  const { checkout, loading } =
    React.useContext(StoreContext);
    const token = getUser().token;
  const emptyCart = checkout.lineItems.length;
  const handleCheckout = () => {
    associateCustomer({
      variables: {
        checkoutId: checkout.id,
        customerAccessToken: token
      },
      onCompleted: () => {
        window.location.assign(checkout.webUrl);
      },
      onError: (error) => console.log(error.message)
    })
      //window.location.assign(checkout.webUrl);
  };
    const [associateCustomer] = useMutation(ASSOCIATE_CUSTOMER);
  return (
    <Layout>
      <div className="container mx-auto">
        <h1 className="lg:text-5xl  font-bold leading-tight text-3xl my-8">
          Shopping cart
        </h1>

        <div className="col-12">
          {checkout.ready === undefined ? (
            <Loader />
          ) :
          emptyCart > 0 ?
          (
            <div>
              <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left text-gray-500">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr>
                      <th scope="col" className="py-3 px-6">
                        <span className="sr-only">Image</span>
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Product
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Qty
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Price
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkout.lineItems.map((item) => (
                      <LineItem item={item} key={item.id} />
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="text-end text-uppercase my-6 font-bold text-xl">
                Total Amount:{" "}
                {formatPrice(
                  checkout.totalPriceV2.currencyCode,
                  checkout.totalPriceV2.amount
                )}
              </div>
              <div className="text-center py-5">
                <button
                  onClick={handleCheckout}
                  disabled={loading}
                  className="text-white block mx-auto bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                  Checkout
                </button>
              </div>
            </div>
          ) : <div>Cart is empty</div> }
        </div>
      </div>
    </Layout>
  );
};

export default Cart;
